<template>
  <div>
    <b-modal
    class="onboarding-modal"
    id="onboardingWidget"
    ref="onboardingWidget"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    >
    <template #modal-header>
      <h2 v-if="session == 'initial'">
        <h2 class="title">Bem-vindo(a) à Tipa</h2>
        <p class="description">
          Para iniciar, para qual finalidade você pretende usar sua nova conta na Tipa
        </p>
      </h2>
    </template>

    <template #default >
      <div v-if="session == 'initial'" class="welcome-container">
          <div class="options-container">
            <!-- Criador de Conteúdo -->
            <div class="option-card creator" @click="changeSession('address')">
              <h4 class="option-title-creator">Criador de Conteúdo</h4>
              <p class="option-description-creator">
                Faço lives e transmissões ao vivo na Twitch, Kick, Youtube, Booyah ou Trovo.
              </p>
            </div>

            <!-- Apoiador -->
            <div class="option-card supporter" @click="changeSession('address')">
              <h4 class="option-title-supporter">Apoiador</h4>
              <p class="option-description-supporter">
                Quero enviar doações e mensalidades para um criador que usa a Tipa.
              </p>
            </div>

            <!-- Outros -->
            <div class="option-card other" @click="changeSession('address')">
              <h4 class="option-title-other">Outros</h4>
              <p class="option-description-other">
                Qualquer outro motivo não especificado anteriormente.
              </p>
              <!-- <input type="text" placeholder="Adicione o motivo aqui" class="custom-input" /> -->
            </div>
          </div>
        </div>

        <form-creator v-else-if="session == 'creator'" v-on:finishCreator="() => { changeSession('finish-creator') }"
          v-on:avatarSession="() => { changeSession('avatar', false) }"
        />

        <div v-else-if="session == 'finish-creator'">
          <p>
            Quase lá, Agora
          </p>
          <ol>
            <li class="steps">
              Adicione nossa overlay em seu programa de stream
              <small>(ou a integração com Streamlabs/Streamelements)</small>
            </li>
            <li class="steps">
              Customize a sua página/perfil
            </li>
            <li class="steps">
              Divulgue o seu link para a tipa ou use o QRCode em sua stream
            </li>
          </ol>
          <div class="text-content">
            <h6>quer falar com a tipa?</h6>
            <p>e de quebra ficar sabendo em primeira mão de todas as novidades?</p>
          </div>
          <div class="social-footer mb-4">
            <a href="https://twitter.com/tipa_ai" class="btn-social btn-twitter">
              <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter</title><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
              <span>Twitter</span>
            </a>
            <a href="https://bit.ly/tipadiscord" class="btn-social btn-discord">
              <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Discord</title><path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"/></svg>
              <span>Discord</span>
            </a>
            <a href="https://instagram.com/tipa_ai" class="btn-social btn-instagram">
              <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
              <span>Instagram</span>
            </a>
          </div>
          <div class="d-flex">
            <b-button block class="mt-0 mr-2" variant="primary" @click="onModalCancel('segment_creator')">
              Continuar
            </b-button>
          </div>
        </div>

        <div v-if="session === 'address'" class="address-container">
          <h2 class="title">Endereço</h2>
          <p class="description">Informe seu endereço abaixo:</p>
          <b-form @submit.prevent="nextStepAddress">
            <b-form-group label="CEP" :disabled="zipcodeLoading">
              <b-form-input type="number" maxlength="8" v-model="form.address_zipcode" class="input-field" required />
            </b-form-group>
            <b-form-group label="Endereço" :disabled="zipcodeLoading">
              <b-form-input type="text" maxlength="120" v-model="form.address_street" class="input-field" required />
            </b-form-group>
            <div class="d-flex">
              <b-form-group label="Número" class="flex-grow-1 mr-2">
                <b-form-input ref="numberField" maxlength="50" type="text" v-model="form.address_number" class="input-field" required />
              </b-form-group>
              <b-form-group label="Complemento" class="flex-grow-1">
                <b-form-input type="text" maxlength="100" v-model="form.address_complement" class="input-field" />
              </b-form-group>
            </div>
            <b-form-group label="Bairro">
              <b-form-input v-model="form.address_neighborhood" type="text" placeholder="Bairro" class="input-field" required/>
            </b-form-group>
            <div class="d-flex">
              <b-form-group label="Cidade" :disabled="zipcodeLoading" class="flex-grow-2 mr-2">
                <b-form-input type="text" maxlength="100" v-model="form.address_city" class="input-field" required />
              </b-form-group>
              <b-form-group label="Estado" :disabled="zipcodeLoading" class="flex-grow-1">
                <b-form-input type="text" maxlength="2" v-model="form.address_state" class="input-field" required />
              </b-form-group>
            </div>
            <b-button block type="submit" class="btn-continue">
              Continuar
            </b-button>
          </b-form>
        </div>

        <div v-if="session === 'social-media1'" class="social-media-container">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css">
          <h2 class="title">Redes Sociais</h2>
          <p class="description">Adicione suas redes sociais e links...</p>
          <div class="link-group social-footer mb-4">
            <a class="btn-social-media" data-label="YouTube" @click="toggleField('youtube_url')">
              <i class="fa-brands fa-youtube"></i>
            </a>
            <a class="btn-social-media" data-label="Twitch" @click="toggleField('twitch_url')">
              <i class="fa-brands fa-twitch"></i>
            </a>
            <a class="btn-social-media" data-label="Twitter" @click="toggleField('twitter_url')">
              <i class="fa-brands fa-twitter"></i>
            </a>
            <a class="btn-social-media" data-label="TikTok" @click="toggleField('tiktok_url')">
              <i class="fa-brands fa-tiktok"></i>
            </a>
            <a class="btn-social-media" data-label="Instagram" @click="toggleField('instagram_url')">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a class="btn-social-media" data-label="Facebook" @click="toggleField('facebook_url')">
              <i class="fa-brands fa-facebook"></i>
            </a>
            <a class="btn-social-media" data-label="Trovo" @click="toggleField('trovo_url')">
              <i class="fa-brands fa-t"></i>
            </a>
            <a class="btn-social-media" data-label="Website" @click="toggleField('site_url')">
              <i class="fa-solid fa-globe"></i>
            </a>
          </div>
          <b-form @submit.prevent="nextStepSocialMedia">
            <b-form-group v-if="visibleFields.youtube_url" label="YouTube">
              <b-form-input v-model="form.youtube_url" type="text" placeholder="https://www.youtube.com/@username" class="input-field" />
            </b-form-group>

            <b-form-group v-if="visibleFields.twitch_url" label="Twitch">
              <b-form-input v-model="form.twitch_url" type="text" placeholder="https://www.twitch.tv/username" class="input-field" />
            </b-form-group>

            <b-form-group v-if="visibleFields.twitter_url" label="X (Twitter)">
              <b-form-input v-model="form.twitter_url" type="text" placeholder="https://www.x.com/username" class="input-field" />
            </b-form-group>

            <b-form-group v-if="visibleFields.tiktok_url" label="TikTok">
              <b-form-input v-model="form.tiktok_url" type="text" placeholder="https://www.tiktok.com/@username" class="input-field" />
            </b-form-group>

            <b-form-group v-if="visibleFields.instagram_url" label="Instagram">
              <b-form-input v-model="form.instagram_url" type="text" placeholder="https://www.instagram.com/username" class="input-field" />
            </b-form-group>

            <b-form-group v-if="visibleFields.facebook_url" label="Facebook">
              <b-form-input v-model="form.facebook_url" type="text" placeholder="https://www.facebook.com/username" class="input-field" />
            </b-form-group>

            <b-form-group v-if="visibleFields.trovo_url" label="Trovo">
              <b-form-input v-model="form.trovo_url" type="text" placeholder="https://www.trovo.live/username" class="input-field" />
            </b-form-group>

            <b-form-group v-if="visibleFields.site_url" label="Site">
              <b-form-input v-model="form.site_url" type="text" placeholder="https://www.seuSite.com" class="input-field" />
            </b-form-group>

            <b-button block type="submit" class="btn-continue">
              Continuar
            </b-button>
          </b-form>
        </div>

        <div v-else-if="session === 'avatar'" class="avatar-container">
          <h2 class="title">Perfil</h2>
          <p class="description">Precisamos de mais algumas informações para finalizar</p>

          <!-- Avatar Upload -->
          <div class="avatar-upload-container">
            <div class="avatar-placeholder" @click="showAvatarCropper = true">
              <img src="@/assets/icons/upload.vue" alt="Avatar" class="avatar-icon" />
            </div>
            <p class="avatar-text">Adicione um avatar</p>
          </div>

          <!-- Avatar Cropper -->
          <avatar-cropper
            v-model="showAvatarCropper"
            :inline="false"
            @cancel="showAvatarCropper = false"
            @error="showAvatarCropper = false"
            :upload-url="avatarUploadPath"
            upload-form-name="avatar"
            :labels="cropperOptions.labels"
            :request-options="avatarUploadHeaders"
            :cropper-options="{
              movable: false,
              zoomable: true,
              rotatable: true,
              scalable: true,
              minCropBoxWidth: 150,
              maxCropBoxWidth: 350,
              aspectRatio: 1,
              autoCropArea: 1,
              viewMode: 3
            }"
            @uploaded="handleUploadedAvatar"
          />

          <!-- Envolver os inputs em um <b-form> com novalidate -->
          <b-form>
            <!-- Nome de Exibição -->
            <b-form-group label="Nome de exibição no Perfil">
              <b-form-input v-model="form.nickname" maxlength="120" type="text" class="input-field" required/>
            </b-form-group>

            <!-- Link do perfil -->
            <b-form-group label="Link do seu perfil">
              <b-input-group>
                <b-input-group-prepend is-text class="input-disabled">
                  https://tipa.ai/
                </b-input-group-prepend>
                <b-form-input
                  v-model="form.username"
                  maxlength="120"
                  type="text"
                  placeholder="username"
                  class="input-field"
                  required
                  @input="debouncedCheckUsername"
                />
              </b-input-group>
              <small v-if="usernameError" class="text-danger">Este username já está em uso. Tente outro!</small>
            </b-form-group>
            <!-- Valor Mínimo de Doação -->
            <b-form-group label="Valor mínimo de doação">
              <div class="input-group">
                <span class="input-group-text">R$</span>
                <b-form-input
                  v-model="form.minimum_tip_amount"
                  type="number"
                  step="0.01"
                  min="1.00"
                  class="input-field"
                  required
                  @input="validateMinimumAmount"
                />
              </div>
            </b-form-group>
            <!-- Botão Avançar -->
            <!-- <b-button block type="submit" class="btn-continue" @click="changeSession('finish-donator')">
              Avançar
            </b-button> -->
            <b-button
              block
              type="submit"
              class="btn-continue"
              @click="changeSession('finish-donator')"
              :disabled="usernameError || !form.username.trim()"
            >
              Avançar
            </b-button>
          </b-form>
        </div>

        <div v-else-if="session == 'finish-donator'" class="final-container">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css">
          <h2 class="title">Tudo certo</h2>
          <p class="description">
            Sua conta foi criada com sucesso e está pronta para receber doações
          </p>

          <div class="page-link">
            <p>Sua página</p>
            <a class="nickname-link" @click="copyToClipboard" ref="nicknameLink">
              <span class="base-url">tipa.ai/</span> <span class="username">{{ form.username }}</span>
              <i class="fa-solid fa-link"></i>
            </a>
            <span v-if="copySuccess" class="copy-message">Texto copiado!</span>
          </div>
          <div class="next-steps">
            <p class="next-title">Próximos passos</p>
            <ol class="steps-list">
              <li>Crie e adicione a overlay da tipa em seu programa de transmissão</li>
              <li>Customize a sua página de perfil</li>
              <li>Divulgue seu link e QR Code e comece a receber doações pela Tipa</li>
            </ol>
          </div>

          <div class="contact-section">
            <p class="contact-title">Quer falar com a Tipa?</p>
            <p class="contact-description">E de quebra ficar sabendo em primeira mão de todas as novidades?</p>
            <div class="social-footer mb-4">
              <a href="https://twitter.com/tipa_ai" class="btn-social btn-twitter">
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Twitter</title><path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/></svg>
                <span>twitter</span>
              </a>
              <a href="https://bit.ly/tipadiscord" class="btn-social btn-discord">
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Discord</title><path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"/></svg>
                <span>discord</span>
              </a>
              <a href="https://instagram.com/tipa_ai" class="btn-social btn-instagram">
                <svg role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>Instagram</title><path d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"/></svg>
                <span>instagram</span>
              </a>
            </div>
          </div>
          <b-button block class="btn-conclude" variant="primary" @click="submitUserData">
            Concluir
          </b-button>
        </div>
      </template>

      <template #modal-footer>
        <b-progress :style="{ width: '100%' }" :value="currentProgressPercentage" :max="100" height="5px" animated></b-progress>
      </template>
    </b-modal>
    <!-- body-class="bg-dark" header-bg-variant="dark" footer-bg-variant="dark" footer-border-variant="dark" header-border-variant="dark" header-close-variant="light" -->
  </div>
</template>

<script>
import FormCreator from './formCreator.vue'
import UserService from '@/services/user.service'
import AvatarCropper from 'vue-avatar-cropper'
import PremiumSubService from '@/services/premiumSub.service.js'
import _ from 'lodash'

export default {
  name: 'OnboardingWidget',
  data: () => ({
    session: 'initial',
    form: {
      address_zipcode: '',
      address_street: '',
      address_number: '',
      address_complement: '',
      address_neighborhood: '',
      address_city: '',
      address_state: '',
      youtube_url: '',
      facebook_url: '',
      trovo_url: '',
      site_url: '',
      twitter_url: '',
      twitch_url: '',
      instagram_url: '',
      tiktok_url: '',
      nickname: '',
      username: '',
      minimum_tip_amount: '',
      avatar_url: ''
    },
    visibleFields: {
      youtube_url: false,
      twitch_url: false,
      twitter_url: false,
      tiktok_url: false,
      instagram_url: false,
      facebook_url: false,
      trovo_url: false,
      site_url: false
    },
    copySuccess: false,
    zipcodeLoading: false,
    addressLoading: false,
    showAvatarCropper: false,
    usernameError: false,
    cropperOptions: {
      labels: {
        submit: 'Salvar',
        cancel: 'Cancelar'
      }
    },
    currentProgressPercentage: 0
  }),
  components: {
    FormCreator,
    AvatarCropper
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isLoggedIn () {
      return this.$store.getters['user/loggedIn']
    },
    avatarUploadHeaders () {
      const token = this.$store.getters['user/token']
      console.log(token)
      return { method: 'POST', headers: { Authorization: token, Accept: 'application/json' } }
    },
    avatarUploadPath () {
      return UserService.avatarUploadPath()
    }
  },
  mounted () {
    this.initialize()
    this.loadUserData() // Carrega os dados do usuário, incluindo o username
    this.debouncedCheckUsername = _.debounce(this.checkUsernameAvailability, 500)
  },
  watch: {
    'form.address_zipcode': {
      immediate: false,
      handler (newVal) {
        if (newVal && newVal.length === 8) {
          this.lookup()
        }
        this.form.address_zipcode = newVal.replace(/\D/g, '')
      }
    }
  },
  methods: {
    async initialize () {
      try {
        const response = await UserService.getInfoOnboarding()

        if (response.data.enabled && !response.data.skipped) {
          this.showModal()
          this.form.username = response.data.username
          this.form.minimum_tip_amount = response.data.minimum_tip_amount || '1.00'
        }
      } catch (error) {
        console.log(error)
      }
    },
    async lookup () {
      try {
        this.zipcodeLoading = true
        const resultZip = await PremiumSubService.zipLookup(this.form.address_zipcode)
        this.form.address_street = resultZip.data.logradouro || this.form.address_street
        this.form.address_neighborhood = resultZip.data.bairro
        this.form.address_city = resultZip.data.localidade
        this.form.address_state = resultZip.data.uf

        this.zipcodeLoading = false

        // this.$refs.numberField.focus()
      } catch (_) {
        this.$toasted.error('Não encontramos o CEP informado')
        this.zipcodeLoading = false
      }
    },
    async loadUserData () {
      try {
        const response = await UserService.getUserData() // Busca os dados do usuário
        if (response.data) {
          // Preenche os dados do formulário com os dados atuais do usuário
          this.form.username = response.data.username || ''// Atualiza apenas o username
        }
      } catch (error) {
        console.error('Erro ao carregar os dados do usuário:', error)
      }
    },
    async checkUsernameAvailability () {
      if (!this.form.username.trim()) {
        this.usernameError = false // Limpa o erro se o campo estiver vazio
        return
      }
      try {
        const response = await UserService.checkUsername(this.form.username.trim())
        // Verifica a propriedade correta na resposta da API
        if (response.data.available) {
          this.usernameError = false// Username disponível
        } else {
          this.usernameError = true// Username já em uso
        }
        // Força a reatividade no Vue
        this.$forceUpdate()
      } catch (error) {
        console.error('Erro ao verificar disponibilidade do username:', error)
        this.usernameError = true// Bloqueia a continuação em caso de erro
      }
    },
    // Método para processar o upload do avatar
    async handleUploadedAvatar (req) {
      this.showAvatarCropper = false
      if (req.response.status === 200) {
        req.response.json().then(data => {
          this.form.avatar_url = data.url
          this.$store.commit('user/updateAvatar', data.url)
        })
      }
    },
    async submitUserData () {
      try {
        await UserService.updateProfile(this.form) // Envia os dados para atualização
        await this.$store.commit('user/updateCurrentUser') // Atualiza o estado global
        await UserService.skipOnboarding(this.form.segment)
        this.hideModal() // Fecha a modal
        console.log('Dados do usuário atualizados com sucesso!')
      } catch (error) {
        console.error('Erro ao atualizar perfil:', error)
      }
    },
    validateMinimumAmount () {
      if (this.form.minimum_tip_amount < 1) {
        this.form.minimum_tip_amount = 1 // Garante que o valor mínimo é 1
      }
    },
    copyToClipboard () {
      const linkText = `tipa.ai/${this.form.username}`
      navigator.clipboard.writeText(linkText).then(() => {
        this.copySuccess = true // Exibe a mensagem
        setTimeout(() => { this.copySuccess = false }, 1500) // Esconde após 1,5s
      }).catch(err => {
        console.error('Erro ao copiar:', err)
      })
    },
    toggleField (field) {
      if (this.visibleFields[field]) {
        if (!this.form[field]) {
          this.visibleFields[field] = false
        }
      } else {
        this.visibleFields[field] = true
      }
    },
    nextStepAddress () {
      this.changeSession('social-media1')
    },
    nextStepSocialMedia () {
      this.changeSession('avatar')
    },
    showModal () {
      this.$refs.onboardingWidget.show()
    },
    hideModal () {
      this.$refs.onboardingWidget.hide()
    },
    changeSession (sessionName, changeCurrentSession = true) {
      if (changeCurrentSession) {
        this.session = sessionName
      }
      switch (sessionName) {
      case 'initial':
        this.currentProgressPercentage = 5
        break
      case 'address':
        this.currentProgressPercentage = 30
        break
      case 'social-media1':
        this.currentProgressPercentage = 50
        break
      case 'avatar':
        this.currentProgressPercentage = 80
        break
      case 'finish-donator':
        this.currentProgressPercentage = 100
        break
      case 'finish-creator':
        this.currentProgressPercentage = 100
        break
      }
    },
    async onModalCancel (segment) {
      await this.updateUserData()
      await UserService.skipOnboarding(segment)
      await this.$store.commit('user/updateCurrentUser')

      this.hideModal()

      if (this.session === 'finish-creator') {
        this.$router.push('/settings')
      }
    }
  }
}
</script>

<style lang="scss">
.welcome-container {
  text-align: left;
  padding: 0px;
  max-width: 360px;
  margin: auto;
}

.title {
  font-size: 22px;
  text-align: left;
  line-height: 1.2;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  font-weight: bold;
  padding: 10px;

}

.description {
  font-size: 15px;
  text-align: left;
  line-height: 1.5;
  color: rgba(88, 88, 88, 0.653);
  text-shadow: none;
  font-weight: normal;
  padding: 10px;
  padding-bottom: 0px;
}

.options-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.option-card {
  padding: 18px;
  border-radius: 8px;
  border-width: 2px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 15px;
  text-align: left;
  border: 1px solid transparent;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.05);
}

.option-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.input-disabled{
  background-color: #9b9b9b3e !important;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  height: 35px;
}
.option-title-other {
  font-size: 15px;
  text-align: left;
  line-height: 1.2;
  color: rgb(106, 47, 181);
  text-shadow: none;
  font-weight: bold;
}

.option-description-other {
  font-size: 13px;
  text-align: left;
  line-height: 1.5;
  color: rgb(106, 47, 181);
  text-shadow: none;
}
.option-title-supporter {
  font-size: 15px;
  text-align: left;
  line-height: 1.2;
  color: rgb(0, 186, 179);
  text-shadow: none;
  font-weight: bold;
}
.option-description-supporter {
  font-size: 13px;
  text-align: left;
  line-height: 1.5;
  color: rgb(26, 113, 110);
  text-shadow: none;
}
.option-title-creator {
  font-size: 15px;
  text-align: left;
  line-height: 1.2;
  color: rgb(242, 104, 34);
  text-shadow: none;
  font-weight: bold;
}

.option-description-creator {
  font-size: 13px;
  text-align: left;
  line-height: 1.5;
  color: rgb(135, 60, 30);
  text-shadow: none;
}
.creator {
  border: 1px solid rgba(242, 104, 34, 0.1);
  border-color: rgba(242, 104, 34, 0.1);
  background: rgba(242, 104, 34, 0.05);
}

.supporter {
  background: rgba(51, 204, 204, 0.1);
  border: 1px solid rgba(51, 204, 204, 0.3);
  color: #33cccc;
}

.other {
  background: rgba(153, 102, 255, 0.1);
  border: 1px solid rgba(153, 102, 255, 0.3);
  color: #9966ff;
}

.custom-input {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 14px;
  background: #f8f8f8;
  color: #444;
  transition: all 0.3s ease-in-out;
}

.custom-input:focus {
  border-color: #9966ff;
  background: white;
  outline: none;
}

.address-container {
  max-width: 360px;
  margin: auto;
  padding: 0px;
  text-align: left;
}

.title {
  font-size: 22px;
  font-weight: bold;
  color: #222;
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.input-field {
  background: #f5f5f5;
  border: none;
  padding: 12px;
  font-size: 14px;
  border-radius: 8px;
}

.input-field::placeholder {
  color: #999;
}

.copy-message {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: rgb(0, 186, 179);
  font-weight: bold;
  justify-content: center;
  animation: fadeOut 1s ease-in-out forwards;
}

@keyframes fadeOut {
  0% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

.d-flex {
  display: flex;
  gap: 10px;
}

.btn-continue {
  background: #f4632d;
  border: none;
  color: white;
  font-size: 16px;
  padding: 12px;
  border-radius: 8px;
  margin-top: 20px;
}

.btn-continue:hover {
  background: #e05620;
}

.link-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}
.btn-social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: #d3d3d3;
  transition: all 0.3s ease-in-out;
  position: relative;
  text-decoration: none;
}

/* Efeito ao passar o mouse */
.btn-social-media:hover {
  background-color: #b0b0b0;
  transform: translateY(-5px);
  text-decoration: none;
}

.btn-social-media::after {
  content: attr(data-label);
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

.btn-social-media:hover::after {
  visibility: visible;
  opacity: 1;
}

.avatar-container {
  text-align: left;
  padding: 0px;
  max-width: 360px;
  margin: auto;
}

.avatar-upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.avatar-placeholder {
  width: 80px;
  height: 80px;
  background: #f5f5f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.avatar-icon {
  width: 32px;
  height: 32px;
  opacity: 0.5;
}

.avatar-text {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.input-field {
  background: #f5f5f5;
  border: none;
  padding: 12px;
  border-radius: 6px;
}

.input-field.disabled {
  color: #666;
}

.btn-continue {
  background-color: #f46a1c;
  border: none;
  padding: 12px;
  color: white;
  font-weight: bold;
  border-radius: 6px;
  cursor: pointer;
}

.btn-continue:hover {
  background-color: #e55a10;
}

.final-container {
  text-align: left;
  padding: 0px;
  max-width: 360px;
  margin: auto;
}

.title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
}

.page-link {
  display: flex;
  flex-direction: column;
}

.page-link p {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.page-link:hover {
  background-color: transparent !important;
}

.nickname-link {
  display: inline-flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: #ff6600;
  border-bottom: 2px solid #ff6600;
}

.nickname-link:hover {
  background-color: transparent !important;
  color: #ff6600;
  text-decoration: none;
}

.nickname-link:hover .base-url,
.nickname-link:hover .username {
  color: #ff6600;
}

.nickname-link:hover i {
  color: #ff6600;
}

.base-url {
  color: #ff6600;
}
.base-url:hover{
  border-bottom: #ff6600;
}

.username {
  color: #000;
}

.nickname-link i {
  font-size: 12px;
  margin-left: 5px;
}

.next-steps {
  margin-top: 20px;
}

.next-title {
  font-weight: bold;
  font-size: 14px;
  color: rgb(0, 186, 179);
  margin-bottom: 10px;
}

.steps-list {
  font-size: 14px;
  color: #555;
  list-style-type: decimal;
  padding-left: 15px;
}

.contact-section {
  margin-top: 20px;
}

.contact-title {
  font-weight: bold;
  font-size: 14px;
}

.contact-description {
  font-size: 13px;
  color: #666;
}

.social-footer {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.btn-social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #ddd;
  border-radius: 5px;
  text-decoration: none;
  font-size: 20px;
}

.btn-conclude {
  margin-top: 20px;
  background: #ff6600;
  color: white;
  font-weight: bold;
  font-size: 16px;
}

.modal-content{
  border-radius: 15px;
  border-width: 0px;
  border-color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 8px 15px -8px;
}
#onboardingWidget {
  .modal-header {
    border-bottom: none;
  }

  .modal-footer {
    border-top: none;
  }

  .social-footer {
    display: flex;
    flex-wrap: wrap;

    .btn-social {
      display: block;
      width: 36px;
      height: 36px;
      padding: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      a {
        background: transparent;
        border: none;
        padding: 5px;
        cursor: pointer;
      }
      span {
        color: white;
        display: none;
      }
      svg {
        width: 40px;
        height: 40px;
        transition: transform 0.2s ease-in-out;
      }

      &:hover {
        transform: scale(1.1);
        svg {
          opacity: 1;
        }
      }

      &.btn-twitter {
        padding: 6px;
        background: #1DA1F2;
        svg {
          fill: white;
        }
      }

      &.btn-discord {
        background: #5865F2;
        svg {
          fill: white;
        }
      }

      &.btn-instagram {
        padding: 7px;
        background: #E4405F;
        svg {
          fill: white;
        }
      }
    }
  }
}
</style>
